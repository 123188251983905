import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DateAndTime from 'date-and-time';
import TimeZonePlugin from 'date-and-time/plugin/timezone';

import oIconLibrary from 'utils/iconLibrary';
import { fnConvertTimeZoneAbbrToIana } from 'utils/common';

DateAndTime.plugin(TimeZonePlugin);

type EventRegistrationWindowCloseProps = {
  registrationCloseDateTime: string;
  timeZone?: string;
};

const EventRegistrationWindowClose = ({
  registrationCloseDateTime,
  timeZone = '',
}: EventRegistrationWindowCloseProps) => {
  if (!registrationCloseDateTime) {
    return null;
  }

  const now: Date = new Date();
  const eventIanaTimeZone: string = fnConvertTimeZoneAbbrToIana(timeZone);
  const closeDate: Date = DateAndTime.parse(
    registrationCloseDateTime,
    'YYYY-MM-DDTHH:mm:ss'
  );
  let localTimeCloseDate: Date = closeDate;
  if (eventIanaTimeZone) {
    localTimeCloseDate = DateAndTime.parseTZ(
      registrationCloseDateTime,
      'YYYY-MM-DDTHH:mm:ss',
      eventIanaTimeZone
    );
  }

  const verb: string = localTimeCloseDate > now ? 'ends' : 'ended';
  const closeTime: string = DateAndTime.format(closeDate, 'h:mm A');
  const closeDateFormatted: string = DateAndTime.format(
    closeDate,
    'MMM DD, YYYY'
  );

  return (
    <p>
      <FontAwesomeIcon icon={oIconLibrary.fontAwesome.info} /> Registration{' '}
      {verb} at {closeTime}
      {timeZone ? ` ${timeZone}` : ''} on {closeDateFormatted}.
    </p>
  );
};

export default EventRegistrationWindowClose;
